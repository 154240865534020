import React, { useState, useEffect } from 'react'
import styles from './FeedForm.module.css'
import { ReactComponent as ErrorCircleIcon } from '../../icons/x-circle.svg'
import sdk from '../../sdk'
import { HostCustomFieldFragment, RegisterForCampaignMutation, SocialPlatformEnum } from '../../generated/graphql'
import CustomField, { CustomFieldValue } from '../CustomField'
import { isPossiblePhoneNumber } from 'libphonenumber-js'

const EMAIL_REGEX = /^[\w'+_.-]+@[\w.-]+$/
const IG_USERNAME_REGEX = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
const TT_USERNAME_REGEX = /^(?!.*\.$)@?[\w.]{2,24}$/
const CLEAN_INPUT = [/^\+/, /^\d{1}/]

interface TouchedProps {
  email: boolean
  igUsername: boolean
  ttUsername: boolean
}

interface ErrorProps {
  email: boolean | string
  igUsername: boolean | string
  ttUsername: boolean | string
}

interface FieldProps {
  email: string
  igUsername: string
  ttUsername: string
}

interface FormState {
  formDirty: boolean
  errors: ErrorProps
  touched: TouchedProps
  values: FieldProps
  customFieldValues: CustomFieldValue[]
  customFieldErrors: CustomFieldValue[]
  customFieldTouched: CustomFieldValue[]
}
interface FormProps {
  submitButtonText: string | null
  programHostLcId: string | null
  handleFormComplete(data: RegisterForCampaignMutation): void
  customFields?: HostCustomFieldFragment[] | null
  platforms?: SocialPlatformEnum[] | null
  requiredUsernames?: ('igUsername' | 'ttUsername')[]
}

const apiErrorMsg = 'Error submitting program application.'

const defaultFormState: FormState = {
  formDirty: false,
  values: {
    email: '',
    igUsername: '',
    ttUsername: '',
  },
  errors: {
    email: false,
    igUsername: false,
    ttUsername: false,
  },
  touched: {
    email: false,
    igUsername: false,
    ttUsername: false,
  },
  customFieldValues: [],
  customFieldTouched: [],
  customFieldErrors: [],
}

function removeFieldFromErrors(errors: CustomFieldValue[], value: CustomFieldValue): CustomFieldValue[] {
  return errors.filter((e) => e.id !== value.id)
}

function FeedForm({
  programHostLcId,
  handleFormComplete,
  customFields,
  platforms,
  requiredUsernames = [],
  submitButtonText,
}: FormProps): React.ReactElement {
  const [formState, setFormState] = useState<FormState>(defaultFormState)
  const [apiError, setApiError] = useState<boolean>(false)
  const formDirty = formState.formDirty

  useEffect(() => {
    if (formDirty && window.parent && window.parent !== window) {
      window.parent.postMessage('lc-signup-form-dirty', '*')
    }
  }, [formDirty])

  const igRequired =
    requiredUsernames.includes('igUsername') ||
    (!!platforms && platforms.length === 1 && platforms.includes(SocialPlatformEnum.Instagram))
  const ttRequired =
    requiredUsernames.includes('ttUsername') ||
    (!!platforms && platforms.length === 1 && platforms.includes(SocialPlatformEnum.Tiktok))

  function isUsername(field: keyof FieldProps): boolean {
    return field === 'igUsername' || field === 'ttUsername'
  }

  function registerForCampaign(email: string, igUsername: string, ttUsername: string): void {
    sdk
      .RegisterForCampaign({
        data: {
          igUsername: igUsername || undefined,
          ttUsername: ttUsername || undefined,
          email,
          customFields: formState.customFieldValues.map((v) => {
            return { id: v.id, value: v.value }
          }),
        },
        lcId: programHostLcId || '',
      })
      .then((data) => {
        handleFormComplete(data)
      })
      .catch((error) => {
        console.log(error)
        setApiError(true)
      })
  }

  function validateField(field: keyof FieldProps, value: string): ErrorProps {
    let error = {}
    // If both usernames are not required, then we default to one or the other being required
    if (isUsername(field) && requiredUsernames.length === 0 && !!platforms && platforms.length > 1) {
      if (
        (field === 'igUsername' && value === '' && formState.values.ttUsername === '') ||
        (field === 'ttUsername' && value === '' && formState.values.igUsername === '')
      ) {
        const message = 'At least 1 social username is required.'
        error = {
          igUsername: message,
          ttUsername: message,
        }
      } else {
        // If one of the usernames is filled out, then we clear the error
        error = {
          igUsername: false,
          ttUsername: false,
        }
      }
    }
    if (field === 'email') {
      if (value === '') {
        error = {
          email: 'Email is required.',
        }
      }
      error = {
        email: !EMAIL_REGEX.test(value) ? 'Email is invalid.' : false,
      }
    } else if (field === 'igUsername') {
      if (igRequired && value === '') {
        error = {
          igUsername: 'Instagram Username is required.',
        }
      } else if (!!value.length) {
        const testUsername = value[0] === '@' ? value.substring(1) : value
        error = {
          ...error,
          igUsername: !IG_USERNAME_REGEX.test(testUsername) ? 'Username is invalid.' : false,
        }
      }
    } else if (field === 'ttUsername') {
      if (ttRequired && value === '') {
        error = {
          ttUsername: 'Tiktok Username is required.',
        }
      } else if (!!value.length) {
        const testUsername = value[0] === '@' ? value.substring(1) : value
        error = {
          ...error,
          ttUsername: !TT_USERNAME_REGEX.test(testUsername) ? 'Username is invalid.' : false,
        }
      }
    }
    return {
      ...formState.errors,
      ...error,
    }
  }

  function handleChange(field: keyof FieldProps, value: string): void {
    let newFormState = {
      ...formState,
      values: {
        ...formState?.values,
        [field]: value,
      },
    }
    if (formState?.errors?.[field]) {
      newFormState = {
        ...newFormState,
        errors: validateField(field, value),
      }
    }
    if (!formState?.touched?.[field]) {
      if (isUsername(field) && requiredUsernames.length === 0 && !!platforms && platforms.length > 1) {
        newFormState = {
          ...newFormState,
          touched: {
            ...formState?.touched,
            igUsername: true,
            ttUsername: true,
          },
          formDirty: true,
        }
      } else {
        newFormState = {
          ...newFormState,
          touched: {
            ...formState?.touched,
            [field]: true,
          },
          formDirty: true,
        }
      }
    }
    setFormState(newFormState)
  }

  function handleBlur(field: keyof FieldProps, value: string): void {
    let newFormState = {
      ...formState,
      values: {
        ...formState?.values,
        [field]: value,
      },
      errors: validateField(field, value),
    }
    if (!formState?.touched?.[field]) {
      if (isUsername(field) && requiredUsernames.length === 0 && !!platforms && platforms.length > 1) {
        newFormState = {
          ...newFormState,
          touched: {
            ...formState?.touched,
            igUsername: true,
            ttUsername: true,
          },
        }
      } else {
        newFormState = {
          ...newFormState,
          touched: {
            ...formState?.touched,
            [field]: true,
          },
        }
      }
    }
    setFormState(newFormState)
  }

  function handleCustomFieldChange(value: CustomFieldValue) {
    const { customFieldValues: values, customFieldTouched: touched } = { ...formState }
    const index = values.findIndex((v) => v.id === value.id)
    //mark touched
    if (!touched.some((t) => t.id === value.id)) {
      touched.push(value)
    }

    // //validate
    const customFieldErrors = formState.customFieldErrors
    const config = customFields?.find((c) => c.id === value.id)
    const isRequired = config?.required || false
    const isInErrorState = customFieldErrors.some((cfe) => cfe.id === value.id)
    let errors: CustomFieldValue[] = [...customFieldErrors]
    if (isRequired && value.value === '') {
      if (!isInErrorState) {
        errors.push({
          ...value,
          error: 'Required',
        })
      }
    } else if (value.value) {
      errors = removeFieldFromErrors(errors, value)
    }

    if (value.name === `${value.id}-phone-input` && value.value) {
      value.value = value.value
        .split('')
        .filter((v) => CLEAN_INPUT.some((r) => r.test(v)))
        .join('')
    }

    //record value
    if (index > -1) {
      values[index] = value
    } else {
      values.push(value)
    }

    setFormState({
      ...formState,
      customFieldValues: values,
      customFieldTouched: touched,
      customFieldErrors: errors,
      formDirty: true,
    })
  }

  function handleCustomFieldBlur(value: CustomFieldValue) {
    const { customFieldValues: values, customFieldTouched: touched } = { ...formState }
    const index = values.findIndex((v) => v.id === value.id)

    //mark touched
    if (!touched.some((t) => t.id === value.id)) {
      touched.push(value)
    }

    //validate
    const customFieldErrors = formState.customFieldErrors
    const config = customFields?.find((c) => c.id === value.id)
    const isRequired = config?.required || false
    const isInErrorState = customFieldErrors.some((cfe) => cfe.id === value.id)
    let errors: CustomFieldValue[] = [...customFieldErrors]
    if (isRequired && value.value === '') {
      if (!isInErrorState) {
        errors.push({
          ...value,
          error: 'Required',
        })
      }
    } else if (value.name === 'tiktok-username') {
      if (!!value.value) {
        const validUsername = TT_USERNAME_REGEX.test(value.value)
        if (!validUsername && !isInErrorState) {
          errors.push({
            ...value,
            error: 'Username is invalid.',
          })
        } else if (validUsername && isInErrorState) {
          removeFieldFromErrors(errors, value)
        }
      }
    } else if (value.name === 'instagram-username') {
      if (!!value.value) {
        const validUsername = IG_USERNAME_REGEX.test(value.value)
        if (!validUsername && !isInErrorState) {
          errors.push({
            ...value,
            error: 'Username is invalid.',
          })
        } else if (validUsername && isInErrorState) {
          removeFieldFromErrors(errors, value)
        }
      }
    } else if (value.name === `${value.id}-phone-input` && !isPossiblePhoneNumber(value?.value ?? '')) {
      errors.push({ ...value, error: 'Phone number is invalid.' })
    } else if (value.value) {
      errors = removeFieldFromErrors(errors, value)
    }

    //record value
    if (index > -1) {
      values[index] = value
    } else {
      values.push(value)
    }

    setFormState({
      ...formState,
      customFieldValues: values,
      customFieldTouched: touched,
      customFieldErrors: errors,
    })
  }

  function handleSubmit(e: React.FormEvent): void {
    e.preventDefault()
    if (customFields?.length !== formState.customFieldValues.length) {
      // If formState is missing custom fields
      const missingFields = customFields?.filter((val) => val.type === 'CHECKBOX') // Filtering all the checkbox
      missingFields?.forEach((field) => {
        // Looping through all the missing fields
        if (
          // if the missing field is not present in customFieldValues
          formState.customFieldValues.filter((cf) => {
            return cf.id === field.id
          }).length === 0
        ) {
          formState.customFieldValues.push({ id: field.id, name: field.title, value: 'false' }) // Pushing the missing field
        }
      })
    }
    registerForCampaign(formState.values.email, formState.values.igUsername, formState.values.ttUsername)
  }

  function handleOnClose(): void {
    setApiError(false)
  }

  const formEmptyValueErrors =
    !formState?.values ||
    formState?.values?.email === '' ||
    (requiredUsernames.includes('igUsername') && formState?.values?.igUsername === '') ||
    (requiredUsernames.includes('ttUsername') && formState?.values?.ttUsername === '') ||
    (formState?.values?.igUsername === '' && formState?.values?.ttUsername === '')

  function everyRequiredCustomFieldIsOk(): boolean {
    let result = true
    customFields
      ?.filter((f) => !!f.required)
      .forEach((f) => {
        if (
          formState.customFieldErrors.some((e) => e.id === f.id) ||
          !formState.customFieldTouched.some((t) => t.id === f.id)
        ) {
          result = false
        }
        if (
          (f.type === 'CHECKBOX' || f.type === 'TERMS_CONDITIONS' || f.type === 'SMS_CONSENT') &&
          formState.customFieldValues.some((e) => e.id === f.id) &&
          formState.customFieldValues.filter((e) => e.id === f.id)[0]?.value === 'false'
        ) {
          result = false
        }
        if (f.type === 'DATE' && formState.customFieldValues.some((e) => e.id === f.id)) {
          if (
            // Date Validation
            formState.customFieldValues.filter((e) => e.id === f.id)[0]?.value?.split('-')[0].length !== 4 ||
            formState.customFieldValues.filter((e) => e.id === f.id)[0]?.value?.split('-')[1].length === 0 ||
            formState.customFieldValues.filter((e) => e.id === f.id)[0]?.value?.split('-')[2].length === 0
          ) {
            result = false
          } else if (
            // month validations
            Number(formState.customFieldValues.filter((e) => e.id === f.id)[0]?.value?.split('-')[1]) < 1 ||
            Number(formState.customFieldValues.filter((e) => e.id === f.id)[0]?.value?.split('-')[1]) > 12
          ) {
            result = false
          } else if (
            // Day Validation
            Number(formState.customFieldValues.filter((e) => e.id === f.id)[0]?.value?.split('-')[2]) < 1 ||
            Number(formState.customFieldValues.filter((e) => e.id === f.id)[0]?.value?.split('-')[2]) > 31
          ) {
            result = false
          }
        }
      })
    return result
  }

  const formHasErrors =
    apiError ||
    formEmptyValueErrors ||
    typeof formState?.errors?.email === 'string' ||
    typeof formState?.errors?.igUsername === 'string' ||
    typeof formState?.errors?.ttUsername === 'string' ||
    !everyRequiredCustomFieldIsOk()

  return (
    <>
      {apiError && (
        <div className={styles.apiErrorContainer}>
          <div className={styles.apiErrorIcon}>
            <ErrorCircleIcon width={20} height={20} onClick={handleOnClose} />
          </div>
          <div className={styles.apiErrorColumn}>
            <div className={styles.apiErrorTitle}>Oops! That didn’t work</div>
            <div>{apiErrorMsg}</div>
          </div>
        </div>
      )}
      <form className={styles.FeedForm} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="email-input" className={styles.formLabel}>
            Email
            <span style={{ color: 'red' }}>*</span>
            <input
              type="text"
              className={formState?.errors?.email ? styles.formControlError : styles.formControl}
              id="email-input"
              placeholder="you@example.com"
              value={formState?.values?.email || ''}
              onChange={(e) => handleChange('email', e.target.value)}
              onBlur={(e) => handleBlur('email', e.target.value)}
              name="email"
            />
            {formState?.touched?.email && formState?.errors?.email && (
              <span className={styles.subtitleError}>{formState?.errors?.email}</span>
            )}
          </label>
        </div>
        {platforms?.includes(SocialPlatformEnum.Instagram) && (
          <div className={styles.formGroup}>
            <label htmlFor="igUsername-input" className={styles.formLabel}>
              Instagram username
              {igRequired && <span style={{ color: 'red' }}>*</span>}
              <input
                type="text"
                className={formState?.errors?.igUsername ? styles.formControlError : styles.formControl}
                id="igUsername-input"
                placeholder="@"
                value={formState?.values?.igUsername || ''}
                onChange={(e) => handleChange('igUsername', e.target.value)}
                onBlur={(e) => handleBlur('igUsername', e.target.value)}
                name="igusername"
              />
              {formState?.touched?.igUsername && formState?.errors?.igUsername && (
                <span className={styles.subtitleError}>{formState?.errors?.igUsername}</span>
              )}
            </label>
          </div>
        )}
        {platforms?.includes(SocialPlatformEnum.Tiktok) && (
          <div className={styles.formGroup}>
            <label htmlFor="ttUsername-input" className={styles.formLabel}>
              Tiktok username
              {ttRequired && <span style={{ color: 'red' }}>*</span>}
              <input
                type="text"
                className={formState?.errors?.ttUsername ? styles.formControlError : styles.formControl}
                id="ttUsername-input"
                placeholder="@"
                value={formState?.values?.ttUsername || ''}
                onChange={(e) => handleChange('ttUsername', e.target.value)}
                onBlur={(e) => handleBlur('ttUsername', e.target.value)}
                name="ttusername"
              />
              {formState?.touched?.ttUsername && formState?.errors?.ttUsername && (
                <span className={styles.subtitleError}>{formState?.errors?.ttUsername}</span>
              )}
            </label>
          </div>
        )}
        {!!customFields && (
          <div>
            {customFields.map((f) => {
              const customFieldError = formState.customFieldErrors?.find((v) => v.id === f.id)
              return (
                <CustomField
                  key={`custom-field-${f.id}`}
                  customField={f}
                  value={formState.customFieldValues?.find((v) => v.id === f.id) || null}
                  touched={formState.customFieldTouched?.some((v) => v.id === f.id) || false}
                  error={customFieldError?.error || false}
                  onChange={handleCustomFieldChange}
                  onBlur={handleCustomFieldBlur}
                />
              )
            })}
          </div>
        )}

        <div className={styles.formGroup}>
          <button type="submit" className={styles.submitButton} disabled={formHasErrors}>
            {submitButtonText || 'Join now'}
          </button>
        </div>
      </form>
    </>
  )
}

export default FeedForm
